import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Deprecation Warning:`}</strong>{` This API is deprecated and it is scheduled to be removed on 03/01/2022. Please use (or migrate to) the new version of the `}<a parentName="p" {...{
          "href": "/viewer-authentication-api-v2-getting-started"
        }}>{`Viewer Authentication`}</a>{`.`}</p>
    </InlineNotification>
    <p>{`Besides the player there are some services (such as Chat, Q&A and Combined Embed) that may also require viewer authentication. In order to make the authentication work across these services, additional steps need to be taken at the end of the authentication flow.`}</p>
    <p>{`These services provide a URL, that must be used instead of the default pass url. This URL is sent in the hashConsumer query parameter by the service to the application.`}</p>
    <p>{`Example url in the hashConsumer for chat:`}</p>
    <pre><code parentName="pre" {...{}}>{`https://video.ibm.com/chat/authorize/12345678?resource=%2Fchat%2F12345678
`}</code></pre>
    <p>{`Example script in native PHP that can authenticate the player and the services as well`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Given a json token has already been constructed
$json = json_encode($response);

// Default hash consumer for the player
$hashConsumer = 'https://video.ibm.com/embed/hashlock/pass';
$query = [];

// Check if there is a special hash consumer
if (!empty($_GET['hashConsumer'])) {
    $hashConsumer = $_GET['hashConsumer'];

    // It's recommended to use a better URL parsing
    // technique (like PSR-7 implementations)
    $parts = parse_url($hashConsumer);

    if (!empty($parts['query'])) {
        parse_str($parts['query'], $query);
    }

    // Instead of concatenating url parts it's recommended to use
    // a better URL parsing technique (like PSR-7 implementations)
    $hashConsumer = $parts['scheme'].'://'.$parts['host'].$parts['path'];
}

$query['hash'] = $json;

$hashConsumer = $hashConsumer.'?'.http_build_query($query);

header('Location: '.$hashConsumer);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      